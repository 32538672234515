import React, {useCallback} from 'react';
import logo from './logo.svg';
import './App.css';
import {AppBar, Box, Container, Grid} from "@mui/material";

function App() {

	const handleClick = useCallback((path: string) => {
		window.open(path);
	}, [])
	return (
		<div className="App">
			<Container
				maxWidth="md"
				disableGutters
			>
				<div className="wrap">
					<AppBar
						position="static"
						sx={{
							background: '#000000'
						}}
					>
						<Container
							disableGutters
							sx={{
								background: '#000000',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'start',
								paddingLeft: '20px'
							}}
						>
							<img src={`${process.env.PUBLIC_URL}/images/app_icon_24.png`} alt="logo"/>
							<span className="header-title">하이바이</span>
						</Container>
					</AppBar>
					<Grid
						container
						direction="column"
						sx={{
							justifyContent: "center",
							alignItems: "center",
							height: '100%',
							paddingTop: '50px',
							paddingBottom: '50px'
						}}
					>
						<div className="icon-box">
							<img src={`${process.env.PUBLIC_URL}/images/app_luncher_icon.png`} alt="logo"/>
						</div>
						<Box height={20}/>
						<span className="content-text">
							동네친구부터<br/>FWB까지<br/>하이바이에서 만나요!
						</span>
						<Box height={71}/>
						<div
							className="button-box"
							onClick={() => handleClick('https://apps.apple.com/kr/app/id6458590604')}
						>
							<img className="button-log" src={`${process.env.PUBLIC_URL}/images/Vector.png`} alt="apple-log"/>
							<img className="button-text" src={`${process.env.PUBLIC_URL}/images/app_store.png`} alt="apple-text"/>
						</div>
						<Box height={14}/>
						<div
							className="button-box"
							onClick={() => handleClick('https://play.google.com/store/apps/details?id=com.zbsd.hibye&hl=ko')}
						>
							<img className="button-log" src={`${process.env.PUBLIC_URL}/images/google_play_logo.png`}
							     alt="apple-log"/>
							<img className="button-text" src={`${process.env.PUBLIC_URL}/images/google_play.png`} alt="apple-text"/>
						</div>
					</Grid>
					<div className="app-bottom">
						<div>
							<span className="foot-text">
								지불시도<br/>
								사업자 등록번호: 201-36-01372<br/>
								사업자 주소: 서울특별시 강남구 테헤란로70길 12 (대치동) 402호<br/>
								고객센터 : help@zibulsido.com<br/><br/>
							</span>
							<span className="foot-text2">
								© 2023 Zibulsido
							</span>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default App;
